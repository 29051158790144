import { Component } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import { AuthService } from '@/services/auth-service'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import { LayoutService } from '@/services/layout-service'
import { UserInfoUpdateModel, UserPasswordUpdateModel } from '@/models/user-info-update'
import { ErrorService } from '@/services/error.service'
import { UserManagerHelper } from '../Administration/UserManagerHelper'
import { DrawerItem } from '@/components/shared/Models/drawer-model'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Alert
  }
})
export default class Profil extends Vue {
  private profileNavItems: DrawerItem[] = [
    {
      icon: 'mdi-account-outline',
      title: 'Compte d\'utilisateur',
      to: '/profil'
    }
  ]

  private layoutService = LayoutService.getInstance()
  private authService = AuthService.getInstance()
  private userMnagerHelper = new UserManagerHelper()
  /* eslint-disable @typescript-eslint/camelcase */
  public user = { family_name: '', given_name: '', email: '', groupIds: '', groupIdList: [''], sub: '' }
  public passwordChangeModel: UserPasswordUpdateModel = { subId: '', password: '', newPassword: '' }
  public passwordConfirm = ''
  public alertMessages: string[] = []
  public showAlert = false
  public alertType: 'success' | 'error' = 'success'
  public isInfoLoading = false
  public isPasswordLoading = false

  public mounted () {
    this.layoutService.updateDrawerList(this.profileNavItems)
    this.user = { ...this.authService.getUser() }
    this.user.groupIdList = this.user.groupIds.split(':')
  }

  public destroyed () {
    this.layoutService.updateDrawerList([])
  }

  public getGroupName (groupId: number) {
    return this.userMnagerHelper.roles[--groupId]
  }

  public getColor (groupId: string) {
    return this.userMnagerHelper.getColor(groupId)
  }

  public async updateUserInfo () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    this.alertMessages = []
    this.showAlert = false
    if (isValid) {
      this.isInfoLoading = true
      const userInfo: UserInfoUpdateModel = {
        subId: this.user.sub,
        firstName: this.user.given_name,
        lastName: this.user.family_name,
        email: this.user.email
      }
      const res = await this.authService.updateUserInfo(userInfo).catch(async err => {
        const res = await ErrorService.handleError(err)
        this.alertMessages = res.errors
        this.alertType = 'error'
        this.showAlert = true
      })
      this.isInfoLoading = false
      if (res && res.status === 200) {
        const authUser = this.authService.getUser()
        this.authService.updateAuthEmail(res.data.email)
        authUser.email = res.data.email
        authUser.given_name = res.data.firstName
        authUser.family_name = res.data.lastName
        this.alertMessages = ['L\'enregistrement des données s\'est déroulé avec succès']
        this.alertType = 'success'
        this.showAlert = true
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public async updateUserPassword () {
    const observer = this.$refs.observerPassword as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    this.alertMessages = []
    this.showAlert = false
    if (isValid) {
      this.isPasswordLoading = true
      const userPasswordChange: UserPasswordUpdateModel = {
        subId: this.user.sub,
        password: this.passwordChangeModel.password,
        newPassword: this.passwordChangeModel.newPassword
      }
      const res = await this.authService.ChangePassword(userPasswordChange).catch(async err => {
        const res = await ErrorService.handleError(err)
        this.alertMessages = res.errors
        this.alertType = 'error'
        this.showAlert = true
      }).finally(() => {
        this.showAlert = true
        this.isPasswordLoading = false
      })

      if (res && res.status === 200) {
        this.alertType = 'success'
        this.alertMessages = ['L\'enregistrement des données s\'est déroulé avec succès']
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.showAlert = false
    this.alertMessages = []
  }
}
